import { Grid } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import ptBR from "date-fns/locale/pt-BR";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import orderService from "../../services/OrderService";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Typography from "@mui/material/Typography";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useParams } from "react-router-dom";
import IStore from "../../interfaces/IStore";
import storeService from "../../services/StoreService";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar, Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

registerLocale("pt-BR", ptBR);


interface IProduct {
  [key: string]: string | undefined | number;
  id_product: number;
  code: number;
  description: string;
  amount: number;
  value: number;
}
interface ICustomer {
  codigo_bling: number;
  razao_social: string;
  total_sale: number;
}
interface ICustomerBySeller {
  id: number;
  name: string;
  total: number;
}
interface IOrder {
  orders: number;
}
interface ISale{
  id: number;
  name: string;
  amount: number;
  value: number;
}

interface ISalesBySeller{
  id: number;
  name: string;
  openOrders: number;
  canceledOrders: number;
  completedOrders: number;
}

interface IData {
  maxSale: ISale[]
  products: IProduct[];
  customersBySeller: ICustomerBySeller[];
  orders: IOrder[];
  monthGoal: {
    goal: number,
    percentage: number
  };
  summary: {
    openOrders: number,
    completedOrders: number,
    canceledOrders: number,
    countOpenOrders: number,
    countCompletedOrders: number,
    countCanceledOrders: number,
    newCustomers: number,
    dayOrders: number
  },
  salesBySeller: ISalesBySeller[]
}



const MonthRank = () => {
  const [items, setItems] = useState<IData>();
  const [page, setPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [stores, setStores] = useState<IStore[]>([]);
  const [data, setData] = useState<any>()

  function onlyUnique(value: any, index: any, array: any) {
    return array.indexOf(value) === index;
  }

  const loadList = (page: number) => {
    setLoading(true);
    orderService
      .getMonthRank(Number(id))
      .then((response) => {
        let result: IData = response;
        setItems(result);
        
        //transforma o resultado nos datasets que o gráfico espera
        let uniqueSellers = result.salesBySeller.map((item: ISalesBySeller) => item.name).filter(onlyUnique)
        let dataTest = []
        let datasets = []
        let countSellers = 0
        //para cada vendedor, gera os valores vendidos por dia, preenchendo os dias faltantes com valor 0

        let completedOrders = []
        let openOrders = []
        for(let seller of result.salesBySeller){
          completedOrders.push(seller.completedOrders)
          openOrders.push(seller.openOrders)
        }

        const types = ["Atendido", "Em negociação"]
        datasets.push({
          label: "Atendido",
          data: completedOrders,
          borderColor: colors[0],
          backgroundColor: colors[0],
        })
        datasets.push({
          label: "Em negociação",
          data: openOrders,
          borderColor: colors[1],
          backgroundColor: colors[1],
        })

        setData({
          labels: uniqueSellers.map((name: string) => name),
          datasets: datasets
        })
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const loadStores = () => {
    storeService
      .getAll()
      .then((response) => {
        let data: IStore[] = response.data;
        setStores(data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    loadList(1);
    loadStores()
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      loadList(1);
    }, 20000);


    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Total de Vendas por Vendedor'
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  };

  const getFirstLastName = (fullname: string) => {
    let names = fullname.split(" ")
    return names[0] + " " + names[names.length - 1]
  }

  const colors = [
    "#006699",
    "#ffcc00",
    "#ff9900",
    "#cc0000",
    "#00cc00",
    "#0000cc",
    "#0000ff",
    "#ff0000",
    "#00ff00",
    "#006699",
    "#ffcc00",
    "#ff9900",
    "#cc0000",
    "#00cc00",
    "#0000cc",
    "#0000ff",
    "#ff0000",
    "#00ff00"
  ]

  return (
    <div className="background">
      <div className="header">
        <div className="logo">
          <img src="/logo192.png" alt="logo da JP2Auto" height="60" />
        </div>
        <div className="title">
          { id == "203404783" && <h1>CANAL JP2AUTO + LOJA VIP</h1>}
          { id != "203404783" && stores.length > 0 && <h1>{stores.find((store) => store.id === Number(id))?.name}</h1>}
        </div>
      </div>
      <div className="summary">
        <div className="summary-item">
          <h2>Vendas no Dia</h2>
          <p>{items?.summary.dayOrders}</p>
        </div>
        <div className="summary-item">
          <h2>Meta do Mês</h2>
          <p>{Number(items?.monthGoal.goal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          <br /><strong style={{color: "#4D69F8"}}>{items?.monthGoal.percentage?.toFixed(1)}%</strong></p>
        </div>
        <div className="summary-item">
          <h2>Em negociação</h2>
          <p style={{color: "#3498DB"}}>
            {Number(items?.summary.countOpenOrders)} pedidos<br />
            {Number(items?.summary.openOrders).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          </p>
        </div>
        <div className="summary-item">
          <h2>Atendido</h2>
          <p style={{color: "#008337"}}>
            {Number(items?.summary.countCompletedOrders)} pedidos<br />
            {Number(items?.summary.completedOrders).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          </p>
        </div>
        <div className="summary-item">
          <h2>Pedidos Cancelados</h2>
          <p>
            {Math.floor(Number(items?.summary.countCanceledOrders))} pedidos<br />
            {Number(items?.summary.canceledOrders).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          </p>
        </div>
        <div className="summary-item">
          <h2>Ticket médio</h2>
          <p>
            {(Number(items?.summary.countCompletedOrders) > 0 ? (Number(items?.summary.completedOrders) / Number(items?.summary.countCompletedOrders)) : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          </p>
        </div>
        <div className="summary-item">
          <h2>Novos clientes no Mês</h2>
          <p>{items?.summary.newCustomers}</p>
        </div>
      </div>
      <div className="content">
        <div className="graph">
        { data != undefined &&
          <Chart type="bar" options={options} data={data} />
        }
        </div>
        <div className="rankings">
          <div className="ranking1">
            <div className="ranking" style={{flexGrow: 1.7}}>
              <h2>Clientes que mais compraram</h2>
              { items?.maxSale.map((item: ISale, index: number) => {
                return (
                  <div className="ranking-item" key={String(index)}>
                    <div className="ranking-item-position">{index+1}.</div>
                    <div className="ranking-item-text">
                      <strong>{item.name}</strong>
                    </div>
                    <div className="ranking-item-value">
                    {item.amount} pedidos
                    </div>
                    <div className="ranking-item-value">{Number(item.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                  </div>
                )
              })}               
            </div>
            <div className="ranking">
              <h2>Clientes conquistados</h2>
              { items?.customersBySeller.map((item: ICustomerBySeller, index: number) => {
                return (
                  <div className="ranking-item" key={String(index)}>
                    <div className="ranking-item-position">{index+1}.</div>
                    <div className="ranking-item-text">
                      <strong>{getFirstLastName(item.name)}</strong>
                    </div>
                    <div className="ranking-item-value">{item.total}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="ranking2">
            <div className="ranking">
              <h2>Produtos mais vendidos</h2>
              { items?.products.map((item: IProduct, index: number) => {
                return (
                  <div className="ranking-item" key={String(index)}>
                    <div className="ranking-item-position">{index+1}.</div>
                    <div className="ranking-item-text">
                      <strong>{item.description}</strong>
                    </div>
                    <div className="ranking-item-value">{Number(item.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                  </div>
                )
              })}              
            </div>          
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthRank;
