import { useContext, useEffect, useState } from "react";
import ISeller from "../../interfaces/ISeller";
import "./styles.scss";
import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { BsBellFill } from "react-icons/bs";
import { Close, PostAdd } from "@material-ui/icons";
import { format } from "date-fns";
import { useCustomerService } from "../../hooks/customer";
import { CustomerResponse } from "../../hooks/customer/type";
import moment from "moment";
import { useUserService } from "../../hooks/user";
import ModalContext from "../../context/modal.context";
import CadastroLeads from "../CadastroLeads";
import { ModalDetails } from "../SalesFunnel/components/ModalDetails";
import { useGoalService } from "../../hooks/goal";
import IGoal from "../../interfaces/IGoal";
import IProductGoal from "../../interfaces/IProductGoal";
import AuthContext from "../../context/auth";

import ProgressGoals from "../SalesFunnel/components/ProgressGoals";

interface IList {
	title: string;
	status: string;
	columnColor: string;
}

export const NewSalesFunnel = () => {
  const { getMyCustomersBySeller, getPositivityRate } = useCustomerService();
  const { getCurrentGoalBySeller } = useGoalService();
  const { getAllActive } = useUserService();
  const { getProductGoalsBySeller } = useGoalService();

  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [goalBySeller, setGoalBySeller] = useState<IGoal>();
  const [productsGoalsBySeller, setProductsGoalsBySeller] = useState<IProductGoal[]>();

  const [customers, setCustomers] = useState<CustomerResponse[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<
    CustomerResponse[]
  >([]);

  const [notificationsCustomers, setNotificationsCustomers] =
    useState<CustomerResponse[]>();
  const [isShowingNotifications, setShowingNotifications] =
    useState<boolean>(false);

  const { setOpen, setChildrenModal, open } = useContext(ModalContext);
  const { getRole, isSeller, isAdmin, isSupervisor } = useContext(AuthContext)
  const [ positivityRate, setPositivityRate ] = useState<number>()

  const [goals, setGoals] = useState<IProductGoal[]>([])

  const handleOpenModalCreateLead = () => {
    setChildrenModal(
      <Box sx={{ width: "800px" }}>
        <CadastroLeads />
      </Box>
    );
    setOpen(true);
  };

  const handleOpenModalChangeStatus = (customer: any) => {
    setChildrenModal(<ModalDetails customer={customer} setOpen={setOpen} />);
    setOpen(true);
  };

  const [lists ] = useState<IList[]>([
    {
      title: "Prospecção",
      status: "prospeccao",
      columnColor: "#E6E6E6",
    },
    {
      title: "Qualificação",
      status: "qualificacao",
      columnColor: "#F7D5D5",
    },
    {
      title: "Apresentação",
      status: "apresentacao",
      columnColor: "#FFE5CC",
    },
    {
      title: "Follow Up",
      status: "followUp",
      columnColor: "#FFFFCC",
    },
    {
      title: "Negociação",
      status: "negociacao",
      columnColor: "#CCE5FF",
    },
    {
      title: "Fechamento",
      status: "fechamento",
      columnColor: "#CCFFCC",
    },
    {
      title: "Pós-Venda",
      status: "posvenda",
      columnColor: "#a0e0d4",
    },
  ]);

  const selectSeller = (id: string) => {
    let selectedSeller = sellers.find((item) => item.id == Number(id));
    setSelectedSeller(selectedSeller);
  };

  const handleSearch = (e: any) => {
    let search = e.target.value;
    let filtered = customers;
    filtered = filtered.filter((customer) => {
      return (
        customer.code?.indexOf(search) >= 0 ||
        customer.name?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0 ||
        customer.companyName?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0
      );
    });
    setFilteredCustomers(filtered);
  };

  const loadCustomers = () => {
    setLoading(true);
    getMyCustomersBySeller(selectedSeller?.id)
      .then((data: CustomerResponse[]) => {
        setLoading(false);
        loadNotifications(data);
        setFilteredCustomers(data);
        setCustomers(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const loadNotifications = (customers: CustomerResponse[]) => {
    let notifications: CustomerResponse[] = [];
    notifications = customers?.filter((customer) => {
      let diffDays = moment().diff(moment(customer.nextContactDate), "days");
      if (diffDays >= 0) {
        return customer;
      }
    });
    setNotificationsCustomers(notifications);
  };

  const loadSellers = async () => {
    const response = await getAllActive();
    setSellers(response);
  };

  const loadGoal = async () => {
    if (selectedSeller){
      getCurrentGoalBySeller(selectedSeller?.id).then((data) => {
        setGoalBySeller(data)        
      }).catch((error) => {
        alert("Não foi possível visualizar a meta do vendedor")
      })
    }else if (isSeller()){
      getCurrentGoalBySeller(undefined).then((data) => {
        setGoalBySeller(data)        
      }).catch((error) => {
        alert("Não foi possível visualizar a meta do vendedor")
      })
    }
  }

  const loadProductGoals = async () => {
    if (selectedSeller) {
      getProductGoalsBySeller(selectedSeller?.id)
        .then( (data) => {
          setProductsGoalsBySeller(data)
        })
        .catch( error => {
          console.log('=== erro de loadProductGoals: ', error)
        }
      )
    } else if(isSeller()) {
      getProductGoalsBySeller(undefined)
        .then( (data) => {
          setProductsGoalsBySeller(data)
        })
        .catch( error => {
          console.log('=== erro de loadProductGoals: ', error)
        }
      )
    }
  }

  useEffect(() => {
    loadProductGoals()
    loadCustomers();
    loadGoal();
    loadPositivityRate()
  }, [selectedSeller]);

  useEffect(() => {
    if (!open) {
      loadCustomers();
    }
  }, [open]);

  const loadPositivityRate = () => {
    if (selectedSeller) {
      getPositivityRate(selectedSeller?.id)
        .then( (data: any) => {
          setPositivityRate(data.rate)
        })
        .catch( (error: any) => {
          console.log('=== erro de positivity rate: ', error)
        }
      )
    } else if(isSeller()) {
      getPositivityRate(undefined)
        .then( (data: any) => {
          setPositivityRate(data.rate)
        })
        .catch( (error: any) => {
          console.log('=== erro de positivity rate: ', error)
        }
      )
    }
  }

  useEffect(() => {
    loadSellers();
    loadCustomers();
    loadPositivityRate()
  }, []);

  const getCountCards = (status: string) => {
    if (filteredCustomers){
      return filteredCustomers.filter(
        (customer) =>
          customer.status.toLowerCase() ==
          status.toLowerCase()
      ).length
    }else{
      return 0
    }
  }

  useEffect(() => {
    const goalsList:any = [];

    console.log(goalBySeller)

    if (goalBySeller){ 
      const monthGoal:IProductGoal = {
        description: 'Meta do mês: ',
        value: goalBySeller?.valueGoal! ,
        sold: goalBySeller?.totalSale!,
        type: goalBySeller?.type!
      };
      goalsList.push(monthGoal)
    }

    if(productsGoalsBySeller) {
      productsGoalsBySeller.map(product => {
        goalsList.push(product)
      })
    }

    setGoals(goalsList)
  }, [goalBySeller, productsGoalsBySeller])

  return (
    <>
      <div className="container-funnel">
        <div className="header-funnel">
          { (isAdmin() || isSupervisor()) && 
            <FormControl style={{ width: "300px" }}>
              <InputLabel id="seller-selection-id-label">Vendedor</InputLabel>
              <Select
                id="seller-selection-id"
                labelId="seller-selection-id-label"
                label={"Vendedor"}
                onChange={(event: SelectChangeEvent<string>) => {
                  const { value } = event.target;
                  selectSeller(value);
                }}
              >
                <MenuItem value={""}>Todos</MenuItem>
                {sellers.map((seller) => (
                  <MenuItem key={String(seller.id)} value={seller.id}>{seller.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <FormControl style={{ width: "300px", marginLeft: "20px" }}>
            <TextField
              id="search"
              name="search"
              label="Procure um cliente"
              onChange={handleSearch}
              fullWidth
            />
          </FormControl>

          <div className="container-progress">

            <ProgressGoals goals={goals}/>

            { positivityRate != undefined && 
              <div className="positivity-rate">
                <strong>Taxa de Positivação: </strong>
                <span>{Math.round(positivityRate * 10) / 10} %</span>
              </div>
            }

            <div className="icon-notification">
              {notificationsCustomers != undefined &&
                notificationsCustomers?.length > 0 && (
                  <IconButton
                    sx={{ height: 50, width: 50, color: "#c00" }}
                    onClick={() => {
                      setShowingNotifications(!isShowingNotifications);
                    }}
                  >
                    <BsBellFill />
                  </IconButton>
                )}
            </div>

          </div>

        </div>
        <div className="content-funnel">
          {lists.map((list) => {
            return (
              <div
                className="list-cards"
                style={{ backgroundColor: list.columnColor }}
                key={list.title}
              >
                <div className="list-cards-header">
                  <h4>
                    (
                    { getCountCards(list.status) }
                    ) {list.title}
                  </h4>
                  {isLoading && (
                    <div style={{ textAlign: "center" }}>
                      <img src="./loading.gif" width="30" alt="Carregando..." />
                    </div>
                  )}
                  {list.status === "prospeccao" && (
                    <IconButton
                      sx={{ height: "30px", width: "30px" }}
                      onClick={() => handleOpenModalCreateLead()}
                    >
                      <Icon component={PostAdd} />
                    </IconButton>
                  )}
                </div>
                <div className="list-cards-content">
                {filteredCustomers
                  .filter((customer) => {
                    if (
                      customer.status.toLowerCase() == list.status.toLowerCase()
                    ) {
                      return customer;
                    }
                  })
                  .sort((a, b) => {                    
                    return moment(a.nextContactDate,'YYYY-MM-DD').toDate().getTime() - moment(b.nextContactDate,'YYYY-MM-DD').toDate().getTime()
                  })
                  .map((customer) => {
                    let diffDays = moment(customer.nextContactDate,'YYYY-MM-DD').diff(new Date(), 'days')
                    return (
                      <div
                        className="card"
                        onClick={() => handleOpenModalChangeStatus(customer)}
                        key={customer.id}   
                        style={{ backgroundColor: (diffDays < 0) ? "#f86f6f" : ((diffDays == 0) ? "#ffd84d" : "#fff")}}                     
                      >
                        <div className="card-header" style={{ backgroundColor: (diffDays < 0) ? "#f86f6f" : ((diffDays == 0) ? "#ffd84d" : "#fff")}}>
                          <div className="card-customer-code">
                            #{customer.code}
                          </div>
                          <div className="card-customer-name">
                            {customer.companyName}
                          </div>
                          <div className="">
                            {customer.addressCity && (
                              `${customer.addressCity} - ${customer.addressState}`
                            )}
                          </div>
                          <div className="card-seller-name">
                            {customer.user?.name}
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="card-customer-credit">
                            R$ {Math.round(customer.creditLimit * 10) / 10}
                          </div>
                          <div className="card-customer-date">
                            {moment(customer.nextContactDate).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {isShowingNotifications === true && (
        <div className="container-notification">
          <IconButton
            sx={{ height: 50, width: 50, color: "#c00", float: "right" }}
            onClick={() => {
              setShowingNotifications(!isShowingNotifications);
            }}
          >
            <Close />
          </IconButton>

          <h4 style={{ textAlign: "center" }}>Lembretes de hoje</h4>

          {notificationsCustomers?.map((customer) => {
            return (
              <>
                <div
                  className="card"
                  onClick={() => handleOpenModalChangeStatus(customer)}
                  key={`${customer.id}-notification`}
                >
                  <div className="card-header">
                    <div className="card-customer-code">{`#${customer.code}`}</div>
                  </div>
                  <Button>{customer.companyName}</Button>
									<div className="card-footer">
										<div className="card-customer-date">
											{customer.nextContactDate != undefined &&
												<>
													{`${format(
														new Date(customer.nextContactDate),
														"dd/MM/yyy HH:mm"
													)}`}
												</>
											}
										</div>
									</div>
								</div>
							</>
						)
					})
			  }	
        </div>
      )}		
		</>
	)
};
