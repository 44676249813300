// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-family: sans-serif;
}

.flatlist {
  margin-bottom: 60px;
}

.button-confirm {
  background-color: #0f65e5 !important;
  color: #fff !important;
}

.button-action {
  background-color: #f90 !important;
  color: #fff !important;
}

.button-cancel {
  background-color: rgb(235, 37, 37) !important;
  color: #fff !important;
}

.logo {
  display: flex;
  justify-content: center;
}
.logo img {
  flex: 1 1;
  max-width: 150px;
}

h2 {
  text-align: center;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-family: sans-serif;
}

.titlePage p {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/shared/_global.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAGA;EACE,oCAAA;EACA,sBAAA;AAAF;;AAEA;EACE,iCAAA;EACA,sBAAA;AACF;;AACA;EACE,6CAAA;EACA,sBAAA;AAEF;;AACA;EACE,aAAA;EACA,uBAAA;AAEF;AADE;EACE,SAAA;EACA,gBAAA;AAGJ;;AACA;EACE,kBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AACA;EACE,uBAAA;AAEF;;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,SAAA;EACA,gBAAA;AACJ","sourcesContent":["@import \"../shared/colors\";\n\np{\n  font-family: sans-serif;\n}\n\n.flatlist {\n  margin-bottom: 60px;\n}\n.button-confirm {\n  background-color: $button-color !important;\n  color: $button-text-color !important;\n}\n.button-action {\n  background-color: $button-action-color !important;\n  color: $button-action-text-color !important;\n}\n.button-cancel {\n  background-color: $button-cancel-color !important;\n  color: $button-text-color !important;\n}\n\n.logo {\n  display: flex;\n  justify-content: center;\n  img {\n    flex: 1;\n    max-width: 150px;\n  }\n}\n\nh2 {\n  text-align: center;\n  font-family: sans-serif;\n  font-size: 2rem;\n  font-weight: 500;\n}\n\nh3 {\n  font-family: sans-serif;\n}\n\n.titlePage {\n  & p {\n    font-size: 1.5rem;\n    font-weight: 500;\n    margin: 0;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
