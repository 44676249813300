import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
  Colors,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Spinner } from "react-bootstrap";
import { Box, FormControl, Icon, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from "@mui/material";
import IStore from "../../interfaces/IStore";
import moment from "moment";
import storeService from "../../services/StoreService";
import ISeller from "../../interfaces/ISeller";
import { useUserService } from "../../hooks/user";
import AuthContext from "../../context/auth";
import { Close, Fullscreen } from "@material-ui/icons";
import orderService from "../../services/OrderService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Colors,
);

registerLocale("pt-BR", ptBR);

interface IGraphData {
  labels: string[];
  datasets: {
    label?: string,
    data: number[],
    fill?: boolean
    borderColor?: string
    tension?: number
  }[]
}

interface IAvgTicketData {
  month: number,
  year: number,
  averageSales: string,
  storeName: string,
  sellerName: string,
}

const options: ChartOptions<"line"> = {
    plugins: {
      title: {
        display: true,
        font: { size: 20 },
        padding: {
          top: 0,
          bottom: 25
        }
      },
      colors: {
        // forceOverride: true
      },
    },
    //set Y-axis max value to 10000
    scales: {
      y: {
        stacked: false,
        beginAtZero: true,
        max: 18000
      }
    },
    responsive: true
  };
const generalGraphOptions = {...options, plugins: { title: {...options.plugins?.title, text: "Ticket médio mensal" }, legend: { display: false } } }
const sellerGraphOptions = {...options, plugins: { title: {...options.plugins?.title, text: "Ticket médio mensal - Por vendedores" } } }
const shopGraphOptions = {...options, plugins: { title: {...options.plugins?.title, text: "Ticket médio mensal - Por lojas" } } }
const channelGraphOptions = {...options, plugins: { title: {...options.plugins?.title, text: "Positivação mensal - Por canal" } } }

const getMonthArray = (startDate: Date, endDate: Date): string[] => {
  const result: string[] = [];

  // Initialize a Date object at the start date
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    // Format the month and year in the "mon/yy" format
    const month = currentDate.toLocaleString('pt-BR', { month: 'short' }).toLowerCase(); // "Jan", "Fev", etc.
    const year = currentDate.getFullYear().toString().slice(-2); // Get last 2 digits of the year ("23", "24", etc.)

    // Add to the result array
    if (endDate.getFullYear() - startDate.getFullYear() >= 1) {
      result.push(`${month}/${year}`);
    } else {
      result.push(`${month}`);
    }

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return result;
}
const transformData = (monthsData: IAvgTicketData[], storesData: IAvgTicketData[], sellersData: IAvgTicketData[]) => {
  const monthTotals: Record<string, number> = {}; // For total sales per month
  const sellerTotals: Record<string, Record<string, number>> = {}; // For seller sales per month
  const storeTotals: Record<string, Record<string, number>> = {}; // For store sales per month

  monthsData.forEach((item) => {
    const { month, year, averageSales, storeName, sellerName } = item;
    const sales = parseFloat(averageSales ?? 0); // parse averageSales to a number
    const monthKey = `${year}-${month}`; // Unique key for each month

    // Sum sales by Month
    if (!monthTotals[monthKey]) monthTotals[monthKey] = 0;
    monthTotals[monthKey] += sales;    
  });

  storesData.forEach((item) => {
    const { month, year, averageSales, storeName } = item;
    const sales = parseFloat(averageSales ?? 0); // parse averageSales to a number
    const monthKey = `${year}-${month}`; // Unique key for each month
    
    // Sum sales by Store for each Month
    if (!storeTotals[storeName]) storeTotals[storeName] = {};
    if (!storeTotals[storeName][monthKey]) storeTotals[storeName][monthKey] = 0;
    storeTotals[storeName][monthKey] += sales;
  })

  sellersData.forEach((item) => {
    const { month, year, averageSales, sellerName } = item;
    const sales = parseFloat(averageSales ?? 0); // parse averageSales to a number
    const monthKey = `${year}-${month}`; // Unique key for each month

    // Sum sales by Seller for each Month
    if (!sellerTotals[sellerName]) sellerTotals[sellerName] = {};
    if (!sellerTotals[sellerName][monthKey]) sellerTotals[sellerName][monthKey] = 0;
    sellerTotals[sellerName][monthKey] += sales;

  })

  // Sort months in ascending order by year and month
  const sortedMonthKeys = Object.keys(monthTotals).sort((a, b) => {
    const [yearA, monthA] = a.split('-').map(Number);
    const [yearB, monthB] = b.split('-').map(Number);
    return yearA === yearB ? monthA - monthB : yearA - yearB;
  });

  // First Graph: Total sales per month
  const monthGraphData = sortedMonthKeys.map((monthKey) => {    
    return monthTotals[monthKey] || 0
  });

  // Second Graph: Seller sales per month
  const sellerGraphData = Object.entries(sellerTotals).map(([sellerName, months]) => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
    const splittedName = sellerName.split(" ");
    return ({
      label: splittedName[0] + " " + splittedName[splittedName.length-1],
      data: sortedMonthKeys.map((monthKey) => months[monthKey] || 0), // Fill missing months with 0
      borderColor: randomColor,
      backgroundColor: randomColor,
    })
  });

  // Third Graph: Store sales per month
  const storeGraphData = Object.entries(storeTotals).map(([storeName, months]) => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
    return ({
      label: storeName,
      data: sortedMonthKeys.map((monthKey) => months[monthKey] || 0), // Fill missing months with 0
      //set random color
      backgroundColor: randomColor,
      borderColor: randomColor,
  
    })
  });

  return { monthGraphData, sellerGraphData, storeGraphData };
};

const AverageTicketIndicators = () => {
  const [generalGraphData, setGeneralGraphData] = useState<IGraphData | undefined>()
  const [sellerGraphData, setSellerGraphData] = useState<IGraphData | undefined>()
  const [shopGraphData, setShopGraphData] = useState<IGraphData | undefined>()
  const [channelGraphData, setChannelGraphData] = useState<IGraphData | undefined>()
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("year").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [stores, setStores] = useState<IStore[]>([]);
  const [selectedStore, setSelectedStore] = useState<IStore | undefined>();
  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<ISeller | undefined>();
  const { getAllActiveBySupervisor, getCurrent } = useUserService();
  const { isSeller, isSupervisor, isAdmin } = useContext(AuthContext)
  const [open, setOpen] = useState(false);
  const [expandedGraph, setExpandedGraph] = useState<{data: IGraphData, options: ChartOptions<"line">}>();
  const loadStores = () => {
    storeService.getAll().then((response) => {
      let data: IStore[] = response.data;
      data = data.filter((store) => [203404783, 204308099, 204593611, 204383522].includes(store.id) ) //apenas LOJAS DE APARECIDA, SJC e CANAL LOJISTA
      setStores(data);
    })
  };
  const selectStore = (id: string) => {
    setSelectedStore(stores.find((item) => item.id === Number(id)));
  };
  const loadSellers = async () => {
    const response = await getAllActiveBySupervisor();
    setSellers(response);
  };
  const selectSeller = (id: string) => {
    setSelectedSeller(sellers.find((item) => item.id === Number(id)))
  };
  const handleOpenGraphModal = (data: IGraphData, options: ChartOptions<"line">) => {
    setOpen(true);
    setExpandedGraph({data, options})
  };
  const handleCloseGraphModal = () => {
    setOpen(false);
    setExpandedGraph(undefined)
  };
  const loadAvgTickets = async () => {
    const response = await orderService.getAverageTicketIndicators(startDate, endDate, selectedStore?.id, selectedSeller?.id)
    const data: { months: IAvgTicketData[], stores: IAvgTicketData[], sellers: IAvgTicketData[]} = response.data
    const labels = getMonthArray(startDate, endDate)

    const transformedGraphsData = transformData(data.months, data.stores, data.sellers);

    setGeneralGraphData({
      labels,
      datasets: [{
        data: transformedGraphsData.monthGraphData,
        borderColor: '#13c296',
      }],
    })
    setSellerGraphData({
      labels,
      datasets: transformedGraphsData.sellerGraphData,
    })
    setShopGraphData({
      labels,
      datasets: transformedGraphsData.storeGraphData,
    })
  }

  useEffect(() => {
    loadStores();
    loadSellers().then(() => {
      if (isSeller()){
        getCurrent().then((user: any) => {
          setSelectedSeller(user)
        }).catch((error) => {
          console.log(error)
        })
      }
      loadAvgTickets();
    }).catch((error: any) => {
      console.log(error)
    })
  }, []);
  useEffect(() => {
    loadAvgTickets();
  }, [selectedStore, selectedSeller, startDate, endDate]);

  return (
    <div className="background">
      <div className="filters">
        <div className="filter">
          <div className="period">
            <TextField
              label={"Período inicial"}
              type={"date"}
              fullWidth
              value={startDate.toISOString().split("T")[0]}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const dateString = e.target.value
                if (dateString == ""){
                  //set start date with first day of current year using moment()
                  const start = moment().startOf('year').toDate()
                  setStartDate(start);
                }else{
                  setStartDate(moment(e.target.value, "YYYY-MM-DD").toDate());
                }
              }}
            />
            <TextField
              label={"Período final"}
              type={"date"}
              fullWidth
              value={endDate.toISOString().split("T")[0]}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const dateString = e.target.value
                if (dateString == ""){
                  //set end date with today
                  const end = moment().toDate()
                  setEndDate(end);                  
                }else{
                  setEndDate(moment(e.target.value, "YYYY-MM-DD").toDate());
                }
              }}
              />
          </div>
        </div>
        {(isSupervisor() || isAdmin()) && (
          <>
            <div className="filter">
              <FormControl fullWidth>
                <InputLabel id="loja-selection-id-label">Loja</InputLabel>
                <Select
                  id="loja-selection-id"
                  labelId="loja-selection-id-label"
                  label={"Loja"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    selectStore(event.target.value);
                  } }
                >
                  <MenuItem value={""}>Todas</MenuItem>
                  {stores.map((store) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="filter">
              <FormControl fullWidth>
                <InputLabel id="seller-selection-id-label">Vendedor</InputLabel>
                <Select
                  id="seller-selection-id"
                  labelId="seller-selection-id-label"
                  label={"Vendedor"}
                  onChange={(event: SelectChangeEvent<string>) => {
                    selectSeller(event.target.value);
                  } }
                >
                  <MenuItem value={""}>Todos</MenuItem>
                  {sellers.map((seller) => (
                    <MenuItem value={seller.id}>{seller.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        )}
        {/* <div className="filter">
          <FormControl fullWidth>
            <InputLabel id="channel-selection-id-label">Canal</InputLabel>
            <Select
              id="channel-selection-id"
              labelId="channel-selection-id-label"
              label={"Canal"}
              onChange={(event: SelectChangeEvent<string>) => {
                // selectChannel(event.target.value);
              }}
            >
              <MenuItem value={""}>Todos</MenuItem>
              {/* {channels.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.name}
                </MenuItem>
              ))} 
            </Select>
          </FormControl>
        </div> */}
      </div>
      <div className="content">
        <div className="col">
          <div className="graph">
            { !generalGraphData ? (
              <Spinner animation="border" className="mx-auto my-auto"/>
            ) : (
              <>
                <Icon component={Fullscreen} className="expand-graph" onClick={() => handleOpenGraphModal(generalGraphData, generalGraphOptions)} />
                <Chart type="line" options={generalGraphOptions} data={generalGraphData} />
              </>
            )}
          </div>        
          <div className="graph">
            { !shopGraphData ? (
              <Spinner animation="border" className="mx-auto my-auto"/>
            ) : (
              <>
                <Icon component={Fullscreen} className="expand-graph" onClick={() => handleOpenGraphModal(shopGraphData, shopGraphOptions)} />
                <Chart type="line" options={shopGraphOptions} data={shopGraphData} />
              </>
            )}
          </div>
        </div>
        <div className="col">
          <div className="graph">
            { !sellerGraphData ? (
              <Spinner animation="border" className="mx-auto my-auto"/>
            ) : (
              <>
                <Icon component={Fullscreen} className="expand-graph" onClick={() => handleOpenGraphModal(sellerGraphData, sellerGraphOptions)} />
                <Chart type="line" options={sellerGraphOptions} data={sellerGraphData} style={{flex: 1}} />
              </>
            )}
          </div>
        </div>
        {/*<div className="graph">
          { !channelGraphData ? (
            <Spinner animation="border" className="mx-auto my-auto"/>
          ) : (
            <>
              <Icon component={Fullscreen} className="expand-graph" onClick={() => handleOpenGraphModal(channelGraphData, channelGraphOptions)} />
              <Chart type="bar" options={channelGraphOptions} data={channelGraphData} />
            </>
          )}
        </div>*/}
      </div>
      <Modal
        open={open}
        onClose={handleCloseGraphModal}
        disableAutoFocus
      >
        <Box className="expanded-graph-modal">
          <Icon component={Close} className="close-button" onClick={handleCloseGraphModal} />
          <Chart type="line" options={expandedGraph?.options} data={expandedGraph?.data!} />
        </Box>
      </Modal>
    </div>
  )
}

export default AverageTicketIndicators