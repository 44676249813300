import moment from "moment";
import IStore from "../interfaces/IStore";
import api from "../shared/axios";

class OrderService {
  async getStorePerformance(store?: IStore): Promise<any> {
    let idStore = store ? store.id : null;
    return api
      .get(`/orders/store-performance?store=${idStore}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getMonthRankAp(): Promise<any> {
    return api
      .get(`/orders/month-rank/?idStore=203404783`)
      .then((result) => {
        return Promise.resolve(result.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async getMonthRank(id: number): Promise<any> {
    return api
      .get(`/orders/month-rank/${id}`)
      .then((result) => {
        return Promise.resolve(result.data);
          })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getSellerPerformance(idStore: number | undefined, startDate: Date, endDate: Date): Promise<any> {
    return api
      .get(`/orders/seller-performance?store=${idStore}&start=${moment(startDate).format('YYYY-MM-DD')}&end=${moment(endDate).format('YYYY-MM-DD')}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAverageTicketIndicators(startDate?: Date, endDate?: Date, idStore?: number, idSeller?: number): Promise<any> {
    return api
      .get(`/orders/average-ticket-indicators`, {
        params: {
          startDate,
          endDate,
          store: idStore,
          seller: idSeller
        }
      })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const orderService = new OrderService();
export default orderService;
