import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/auth";
import { Sidebar } from "./sidebar";
import { SideBarItem } from "./type";
import { AppRoutesEnum } from "../../routes/app.routes";

export const SideBarContainer = () => {
  const { singOut, getRole } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    singOut();
  };

  const isEnable = (requiredRoles: string[]) => {
    const role = getRole();
    
    if (!role) {
      return false;
    }

    const filteredRoles = requiredRoles.filter(value => role.split(",").includes(value));

    return filteredRoles.length > 0 || (role.indexOf("admin") >= 0);
  };

  const items: SideBarItem[] = [
    {
      title: "Home",
      onClick: () => {
        navigate(AppRoutesEnum.HOME_SELLER);
      },
      isEnabled: isEnable(["seller"]),
    },
    {
      title: "Produtos",
      onClick: () => {
        navigate(AppRoutesEnum.PRODUCTS);
      },
      isEnabled: isEnable(["admin"]),
    },
    {
      title: "Gestão de estoque",
      onClick: () => {
        navigate(AppRoutesEnum.PRODUCT_STOCK);
      },
      isEnabled: isEnable(["admin"]),
    },
    {
      title: "Logística",

      onClick: () => {
        navigate("/logistica");
      },
      isEnabled: isEnable(["logistics"]),
    },
    {
      title: "Leads Perdidos",

      onClick: () => {
        navigate(AppRoutesEnum.LOST_LEADS);
      },
      isEnabled: isEnable(["seller", "supervisor"]),
    },

    {
      title: "Funil de vendas",

      onClick: () => {
        navigate(AppRoutesEnum.SALES_FUNNEL);
      },
      isEnabled: isEnable(["seller", "supervisor"]),
    },
    {
      title: "Indicadores",

      onClick: () => {
        navigate(AppRoutesEnum.INDICATORS);
      },
      isEnabled: isEnable(["admin", "supervisor"]),
    },
    {
      title: "Indicadores de Base de Cliente",

      onClick: () => {
        navigate(AppRoutesEnum.CLIENT_BASE_INDICATORS);
      },
      isEnabled: isEnable(["admin", "supervisor", "seller"]),
    },
    {
      title: "Indicadores de Ticket médio",

      onClick: () => {
        navigate(AppRoutesEnum.AVERAGE_TICKET_INDICATORS);
      },
      isEnabled: isEnable(["admin", "supervisor", "seller"]),
    },
    {
      title: "Usuários",

      onClick: () => {
        navigate(AppRoutesEnum.USERS);
      },
      isEnabled: isEnable([]),
    },
    {
      title: "Vendedores",

      onClick: () => {
        navigate(AppRoutesEnum.SELLERS);
      },
      isEnabled: isEnable(["supervisor"]),
    },
    {
      title: "Conversão de Clientes",

      onClick: () => {
        navigate(AppRoutesEnum.CUSTOMERS_CONVERSION);
      },
      isEnabled: isEnable(["seller", "supervisor", "admin"]),
    },
    {
      title: "Performance da Loja",

      onClick: () => {
        navigate(AppRoutesEnum.STORE_PERFORMANCE);
      },
      isEnabled: isEnable(["seller", "supervisor", "admin"]),
    },
    {
      title: "Base de Clientes",

      onClick: () => {
        navigate(AppRoutesEnum.MY_CUSTOMERS);
      },
      isEnabled: isEnable(["seller", "supervisor", "admin"]),
    },
    {
      title: "Minhas metas",

      onClick: () => {
        navigate(AppRoutesEnum.SELLERS_GOALS);
      },
      isEnabled: isEnable(["seller"])
    },
    {
      title: "Sair",

      onClick: () => {
        logout();
        navigate(AppRoutesEnum.LOGIN);
      },
      isEnabled: true,
    },
  ];

  return <Sidebar {...{ items }} />;
};
