// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  min-width: 300px;
  background-color: #fff;
}
.sidebar .link-collapse {
  align-items: flex-end;
  display: block;
  text-align: right;
  padding: 10px;
}
.sidebar__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar__menu a {
  text-decoration: none;
}
.sidebar__menu__item {
  display: flex;
  align-items: center;
  place-content: flex-start;
  padding: 1rem 3rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #555;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
}
.sidebar__menu__item.active {
  color: #fff;
  background-color: #308efe;
  margin-right: 1rem;
  margin-left: 1rem;
}
.sidebar__menu__item__icon i {
  font-size: 1.75rem;
}
.sidebar__menu__indicator {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 40px);
  border-radius: 10px;
  background-color: #308efe;
  z-index: -1;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
}

.logo {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;AACF;AACE;EACE,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAII;EACE,qBAAA;AAFN;AAII;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,kCAAA;EACA,qBAAA;AAFN;AAIM;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;AAFR;AAKQ;EACE,kBAAA;AAHV;AAQI;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,wBAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,2BAAA;EACA,4BAAA;AANN;;AAUA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAPF","sourcesContent":[".sidebar {\n  z-index: 1000;\n  top: 0;\n  left: 0;\n  height: 100%;\n  min-width: 300px;\n  background-color: #fff;\n\n  .link-collapse {\n    align-items: flex-end;\n    display: block;\n    text-align: right;\n    padding: 10px;\n  }\n\n  &__logo {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  &__menu {\n    & a {\n      text-decoration: none;\n    }\n    &__item {\n      display: flex;\n      align-items: center;\n      place-content: flex-start;\n      padding: 1rem 3rem;\n      font-size: 1.25rem;\n      font-weight: 500;\n      color: #555;\n      transition: color 0.3s ease-in-out;\n      text-decoration: none;\n\n      &.active {\n        color: #fff;\n        background-color: #308efe;\n        margin-right: 1rem;\n        margin-left: 1rem;\n      }\n      &__icon {\n        i {\n          font-size: 1.75rem;\n        }\n      }\n    }\n\n    &__indicator {\n      position: absolute;\n      top: 0;\n      left: 50%;\n      width: calc(100% - 40px);\n      border-radius: 10px;\n      background-color: #308efe;\n      z-index: -1;\n      transform: translateX(-50%);\n      transition: 0.3s ease-in-out;\n    }\n  }\n}\n.logo {\n  display: flex;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
