// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  width: 800px;
  height: 60px;
  border-radius: 4px;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: left;
  align-items: left;
}

.swiper-slide p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progress-bar{
  flex: 1 1;
  padding-left: 20px;
  padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SalesFunnel/components/ProgressGoals/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;EACzC,eAAe;EACf,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".swiper {\n  width: 800px;\n  height: 60px;\n  border-radius: 4px;\n}\n\n.swiper-slide {\n  text-align: center;\n  display: flex;\n  justify-content: left;\n  align-items: left;\n}\n\n.swiper-slide p {\n  font-family: Arial, Helvetica, sans-serif;\n  font-size: 18px;\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.progress-bar{\n  flex: 1;\n  padding-left: 20px;\n  padding-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
