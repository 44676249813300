import "bootstrap/dist/css/bootstrap.min.css";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useGoalService } from "../../hooks/goal";
import MyCustomers from "./mycustomers";
import { Badge, BadgeProps, Icon, styled } from "@mui/material";
import { useCustomerService } from "../../hooks/customer";
import { CustomerResponse, MyCustomerFilterRequest } from "../../hooks/customer/type";
import ISeller from "../../interfaces/ISeller";
import moment from "moment";
import ICustomersResponse from "../../interfaces/ICustomersResponse";
import AuthContext from "../../context/auth";
import { PositivizationStatus } from "./PositivizationStatus.enum";
import { useFollowupsService } from "../../hooks/followups";
import { Button } from "react-bootstrap";
import { Visibility } from "@material-ui/icons";
import { ModalDetails } from "../SalesFunnel/components/ModalDetails";
import ModalContext from "../../context/modal.context";

export const MyCustomersContainer = () => {
  const [items, setItems] = useState<(CustomerResponse & { nextFollowUp?: Date; lastFollowUp?: Date; })[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedSeller, setSelectedSeller] = useState<ISeller>();
  const { isSeller } = useContext(AuthContext)
  const { getLastAndNextFollowUpDates } = useFollowupsService();
  const { getMyCustomersWithPurchaseBySeller } = useCustomerService();
  const { setOpen, setChildrenModal } = useContext(ModalContext)

  const handleOpenCustomerDetailsModal = (customer: any) => {
    setOpen(true);
    setChildrenModal(<ModalDetails customer={customer} setOpen={setOpen} />);
  };

  const headers = ["Cliente", "Dias sem comprar", "Último follow-up", "Proximo contato", ""];

  const loadList = async (params: MyCustomerFilterRequest) => {
    const response: (CustomerResponse & { nextFollowUp?: Date; lastFollowUp?: Date; })[] = (await getMyCustomersWithPurchaseBySeller(params.seller)).customers;

    for (const item of response) {
      const { nextFollowUp, lastFollowUp } = await getLastAndNextFollowUpDates(item.id);

      item.nextFollowUp = nextFollowUp;
      item.lastFollowUp = lastFollowUp;
    }
    setItems(response);
  };

  const selectSeller = (seller?: ISeller) => {
    setSelectedSeller(seller)
  }

  useEffect(() => {
    fetchData()
  }, [selectedSeller])

  const fetchData = useCallback(async () => {
    setLoading(true);
    await Promise.all([loadList({ seller: selectedSeller?.id })]);
    setLoading(false);
  }, [isLoading, items, selectedSeller]);

  const rows = useMemo(() => {
    if (isLoading) {
      return [
        {
          values: ["Carregando..."],
        },
      ];
    }

    if (items.length == 0) {
      return [
        {
          values: [(isSeller()) ? "Carregando" : "Selecione um vendedor"],
        },
      ];
    }

    return items.map((item: CustomerResponse & { nextFollowUp?: Date; lastFollowUp?: Date; }) => {
      return {
        values: [
          item.companyName,
          (item.lastPurchaseDate) ? moment().diff(moment(item.lastPurchaseDate), 'days') + ' dias' : "Nunca comprou",
          (item.lastPurchaseDate) ? (moment().diff(moment(item.lastPurchaseDate), 'days') > 90 ? PositivizationStatus.Over90Days : (moment().diff(moment(item.lastPurchaseDate), 'days') > 40 ? PositivizationStatus.Between40And90Days : PositivizationStatus.Positivized)) : PositivizationStatus.NeverPurchased,
          (item.lastFollowUp) ? moment(item.lastFollowUp).format("DD/MM/yyyy") : "Não agendado",
          (item.nextFollowUp) ? moment(item.nextFollowUp).format("DD/MM/yyyy") : "Não agendado",
          <Button variant="secondary" type="button"><Icon component={Visibility} style={{fontSize: 20}} onClick={() => handleOpenCustomerDetailsModal(item)}/></Button>,
        ],
      };
    });
  }, [isLoading, items]);

  return <MyCustomers rows={rows} headers={headers} updateList={fetchData} selectSellerParent={selectSeller} />;
};
